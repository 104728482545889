import {Injectable}              from '@angular/core';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {Observable}              from 'rxjs';
import { environment } from '../../environments/environment';

const baseUrl = environment.url;

@Injectable({
    providedIn: 'root'
})

export class ServerService{

	public Currency = { name: 'Dollar', currency: 'USD', price: 1 }

	constructor(private http: HttpClient){ }

    getAll(module): Observable<any>{
		return this.http.get(`${baseUrl}${module}/fetchAll`);
	}
	popularProduct(module): Observable<any>{
		return this.http.get(`${baseUrl}${module}/popularProduct`);
	}
	searchProducts(module, req: object): Observable<any>{
		return this.http.post(`${baseUrl}${module}/search`, req);
	}

	getAllCartItems(module, id): Observable<any>{
		return this.http.get(`${baseUrl}${module}/fetchAll/${id}`);
	}

	getById(module, id): Observable<any>{
		return this.http.get(`${baseUrl}${module}/fetchById/${id}`);
	}

	deleteById(module, id): Observable<any>{
		return this.http.delete(`${baseUrl}${module}/deleteById/${id}`);
	}

	getAllLocation(module, location): Observable<any>{
		return this.http.get(`${baseUrl}${module}/fetchAllLocation/${location}`);
	}

	getBySlug(module, slug): Observable<any>{
		//console.log(`${baseUrl}${module}/fetchBySlug/${slug}`);
		return this.http.get(`${baseUrl}${module}/fetchBySlug/${slug}`)
	}

	getBySlugLocation(module, slug, location): Observable<any>{
		return this.http.get(`${baseUrl}${module}/fetchBySlug/${slug}/${location}`)
	}

	create(data: object, module): Observable<any>{
		return this.http.post(`${baseUrl}${module}/create`, data);
	}

	edit(data: any, module): Observable<any>{
		return this.http.put(`${baseUrl}${module}/updateById/${data.id}`, data);
	}

	status_update(id: number, data: object, module): Observable<any>{
		return this.http.put(`${baseUrl}${module}/statusupdateById/${id}`, data);
	}
	
	cartQtyupdateById(id: number, data: object, module): Observable<any>{
		return this.http.put(`${baseUrl}${module}/cartQtyupdateById/${id}`, data);
	}


	register(data: object): Observable<any>{
		return this.http.post(`${baseUrl}auth/signup`, data);
	}

	registerSeller(data: object): Observable<any>{
		return this.http.post(`${baseUrl}user/create`, data);
	}


	login(data: object): Observable<any>{
		return this.http.post(`${baseUrl}auth/signin`, data);
	}

	addToCart(data: object): Observable<any>{
		return this.http.post(`${baseUrl}cart/create`, data)
	}

	placeOrder(data: object): Observable<any>{
		return this.http.post(`${baseUrl}payment/createOrder`, data)
	}

	updatecart(data: object): Observable<any>{
		return this.http.put(`${baseUrl}payment/updatecart`, data)
	}

	verifyOrder(data: object): Observable<any>{
		return this.http.put(`${baseUrl}payment/verifyOrder`, data)
	}

	getPolicy(type:string): Observable<any>{
		return this.http.get(`${baseUrl}policy/fetch/${type}`);
	}

	requestOrderCancellation(id:bigint): Observable<any>{
		return this.http.put(`${baseUrl}payment/requestCancellation/${id}`, {});
	}

	verifyCoupon(code: any): Observable<any>{
		return this.http.get(`${baseUrl}coupon/verify/${code}`);
	}

	uploadInvoice(data: any, file: File): Observable<any>{
		const formdata: FormData = new FormData();

		formdata.append('file', file);
		formdata.append('filename', data.filename);
		formdata.append('order_id', data.order_id);
		formdata.append('email', data.email);
		formdata.append('vendor_email', data.vendor_email);
		formdata.append('name', data.name);

		const req = new HttpRequest('POST', `${baseUrl}payment/upload`, formdata, {
			reportProgress: true,
			responseType  : 'text'
		});

		return this.http.request(req);
	}

	getShippingRate(data:any){
		return this.http.post<any>(`${baseUrl}payment/calculate-shipping`, data);
	}

	createShippingOrder(data:any){
		return this.http.put<any>(`${baseUrl}payment/createShippingOrder`, data);
	}

	getByCustom(filter: object, module: string): Observable<any>{
		return this.http.post(`${baseUrl}${module}/fetchByCustom`, filter);
	}


}
