import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {ActivatedRoute, Router}                from '@angular/router';
import { QuickViewComponent }                  from "../../modal/quick-view/quick-view.component";
import { CartModalComponent }                  from "../../modal/cart-modal/cart-modal.component";
import { Product }                             from "../../../classes/product";
import { ProductService }                      from "../../../services/product.service";

@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent implements OnInit {

  
  @Input() product: any;
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  @Input() isRelatedProduct: boolean = false;

  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc : string

  constructor(private productService: ProductService, private router: Router) { }

  ngOnInit(): void {
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  ParseImage(imageObj){
    if(imageObj){
      let parsed = JSON.parse(imageObj)[0];
      return parsed.url+parsed.nm;
    } else{
      return;
    }
  }

  getUrl(text){
    return text.replace(/[\(\)]/g, '-').toLowerCase();
  }

  addToCart(product: any) {
    this.productService.addToCart(product);
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }

  redirect(){
    if(this.isRelatedProduct){
      this.router.navigateByUrl('/shop', {skipLocationChange: true})
          .then(() => this.router.navigateByUrl(`/shop/product/${this.product?.id}/${this.getUrl(this.product?.nm)}`));
    }else{
      //alert(this.getUrl(this.product?.nm));
      return this.router.navigateByUrl(`/shop/product/${this.product?.id}/${this.getUrl(this.product?.nm)}`);
    }
  }
}
