<!-- Start SubCategories List -->

    <div class="background sub-categories-block" *ngIf="!noCategories">
        <h1 class="c_f_l">{{slugName}}</h1>
        <!--<span id="sub-categories_toggle">Show less</span>-->
        <div class="row" [ngClass]="{'sub-categories-list': isShowMore}">
        <div class="col" *ngFor="let category of categories" ><a [routerLink]="['/shop', category.slug]" class="collection-content-block" (click)="scroll(category)" >
            <div class="contain-bg">
                <a [routerLink]="['/shop', category.slug]"><img [src]="ParseImage(category.zf)" class="img" alt="categories" style="width: 100%; object-fit: contain;"></a>
                <!--<img src="https://medioffers.s3.ap-south-1.amazonaws.com/modules/category/img/radiology-01.jpg">-->
            </div>
            <h4 >{{category.nm}}</h4>
        </a></div>
        

            </div>
            <button type="button"  (click)="showMoreCategory()">
                {{ isShowMore ? 'Show More': 'Show Less' }}
              </button>
            </div>
        
<!-- End Categories List -->