import { Component, OnInit, Input } from '@angular/core';
import { ServerService } from 'src/app/service/server.service';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/medi-offers.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True

  public policies: any[] = [];
  public details: any;
  public today: number = Date.now();

  constructor(
    public serverService: ServerService
  ) { }

  ngOnInit(): void {
    this.getAllPolicies();
    this.getDetails();
  }

  getDetails(){
    this.serverService.getAll('detail').subscribe(res=>{
      this.details = res.data[0];
    })
  }

  getAllPolicies(){
    this.serverService.getAll('policy').subscribe(res=>{
      this.policies = res.data;
    })
  }

  getUrl(name){
    return name.replace(/\s+/g, '-').toLowerCase();
  }

}
