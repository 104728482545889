import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { ServerService } from 'src/app/service/server.service';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  public categories: any[] = [];
  public categoryList: any[] = [];
  public collapse: boolean = true;
  filterTerm: string;

  public dynamicContent: any = [];
  public baseUrl: string = 'category'
  
  constructor(public productService: ProductService,
    private router: Router,
    private serverService: ServerService,
    private viewScroller: ViewportScroller) { 
  }

  ngOnInit(): void {
    CommonService.fetchAll(this).then(()=>{
      this.categories = this.dynamicContent.filter(i=>i.level==1);
      this.categories.forEach((cat)=>{
				this.getChild(cat)
			})
    }).then(()=>{
			this.setCategoryList();
		})

    

    this.router.events.forEach((event)=>{
      if(event instanceof NavigationEnd){
        // let urlLength = event.url.split('/').length;
        // let slug = event.url.split('/')[urlLength-1]
        // this.getCategoryBySlug(slug);
      }
    })
  }

  setCategoryList(){
		this.categories.forEach((cat)=>{
			this.pushChild(cat);
		})
	}

  scroll(category){
    this.router.navigateByUrl('/shop'+this.getRecursiveUrl(category.id))
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products');
      
  }

  pushChild(cat){
		this.categoryList.push(cat)
		if(cat.children.length>0){
			cat.children.forEach((child)=>{
				this.pushChild(child);
			})
		} else{
			return;
		}
	}

  showDashes(level){
		if(level==1){
			return;
		} else{
			let dashes='';
			for (let index = 1; index < level; index++){
				dashes+='_ ';
			}
			return dashes;
		}
	}

  getChild(cat){
		cat.children = this.dynamicContent.filter(i => i.p==cat.id);
		if(cat.children.length>0){
			cat.children.forEach((child)=>{
				this.getChild(child);
			})
		} else{
			return;
		}
	}

  // getCategoryBySlug(slug){
  //   CommonService.fetchBySlug(this, slug).then(()=>{
  //     this.categories = this.dynamicContent.children;
  //     console.log(this.categories)
  //   })
  // }

  ParseImage(imageObj){
    if(imageObj){
      let parsed = JSON.parse(imageObj)[0];
      return parsed.url+parsed.nm;
    } else{
      return;
    }
  }

  getRecursiveUrl(id){
    let url = '';
    let category = this.dynamicContent.filter(i=> i.id==id);
    url = '/'+category[0].slug;

    if(category[0].p==0){
      return url;
    } 
    else{
      return this.getParent(category[0].p, url);
    }
  }

  getParent(id, url){
    let parent = this.dynamicContent.filter(i=>i.id == id);
    url = '/'+parent[0].slug+url;
    if(parent[0].p==0){
      return url;
    } else{
      return this.getParent(parent[0].p, url);
    }
  }

  getUrl(text){
    return text.replace(/\s+/g, '-').toLowerCase();
  }

  getActiveCategory(slug){
    if(this.router.url.split('/')[this.router.url.split('/').length-1] == slug){
      return true;
    }
  }

}
