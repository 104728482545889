import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor() { }

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			title: 'Home', type: 'link', active: true, path: '/home'
		},
		{
			title: 'Shop', type: 'link', active: false, path: '/shop'
		},
		{
			title: 'Sell on MediOffers', type: 'link', active: false, path: '/seller', badge: true, badgeText: 'register now'
		},
		// {
		// 	title: 'Products', type: 'sub', active: false, children: [
		// 		{
		// 			title: 'sidebar', type: 'sub', active: false, children: [
		// 				{ path: '/shop/product/left/sidebar/trim-dress', title: 'left-sidebar', type: 'link' },
		// 				{ path: '/shop/product/right/sidebar/trim-dress', title: 'right-sidebar', type: 'link' },
		// 				{ path: '/shop/product/no/sidebar/trim-dress', title: 'no-sidebar', type: 'link' }
		// 			]
		// 		},
		// 		{ path: '/shop/product/three/column/trim-dress', title: 'three-column', type: 'link' },
		// 		{ path: '/shop/product/four/image/belted-dress', title: 'four-image', type: 'link' },
		// 		{ path: '/shop/product/bundle/trim-dress', title: 'bundle-product', type: 'link' },
		// 		{ path: '/shop/product/image/outside/trim-dress', title: 'image-outside', type: 'link' }
		// 	]
		// },
	];

	LEFTMENUITEMS: any[] = [
		{
			nm: 'Home', type: 'link', active: true, path: '/home'
		},
		{
			nm: 'Shop', type: 'link', active: false, path: '/shop'
		},
		{
			nm: 'Sell on MediOffers', type: 'link', active: false, path: '/seller', badge: true, badgeText: 'register now'
		},
		{
			nm: 'Products', type: 'sub', active: false, children: [
				{
					nm: 'sidebar', type: 'sub', active: false, children: [
						{ path: '/shop/product/left/sidebar/trim-dress', nm: 'left-sidebar', type: 'link' },
						{ path: '/shop/product/right/sidebar/trim-dress', nm: 'right-sidebar', type: 'link' },
						{ path: '/shop/product/no/sidebar/trim-dress', nm: 'no-sidebar', type: 'link' }
					]
				},
				{ path: '/shop/product/three/column/trim-dress', nm: 'three-column', type: 'link' },
				{ path: '/shop/product/four/image/belted-dress', nm: 'four-image', type: 'link' },
				{ path: '/shop/product/bundle/trim-dress', nm: 'bundle-product', type: 'link' },
				{ path: '/shop/product/image/outside/trim-dress', nm: 'image-outside', type: 'link' }
			]
		},
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	leftMenuItems = new BehaviorSubject<any[]>(this.LEFTMENUITEMS);

}
