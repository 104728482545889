

<div class="product-box-related">
<div *ngIf="!loader" class="equal-height-grid">
  <div class="img-wrapper" style="height: 220px;">
    <div class="lable-block">
      <span class="bg-theme text-white " style="position: absolute; padding: 10px;padding: 3px 7px;
      border-radius: 2px;
      font-size: 13px;
      font-weight: 500;" *ngIf="product.fa">Best Seller</span>
      <!-- <span class="lable4" *ngIf="product.sale">on sale</span> -->
    </div>
    <div class="front">
      <a (click)="redirect()">
        <img 
          [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
          [lazyLoad]="ImageSrc ? ImageSrc : ParseImage(product.zf)" 
          class="img-fluid lazy-loading" 
          alt="{{ getUrl(product.nm) }}" style="height: 200px !important; width: 100%; object-fit: cover;" />
      </a>
    </div>

    <div class="cart-info cart-wrap">
      <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
        <i class="" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><path fill="#f22e62" d="M12.11 9.84l-1-1.73.86-.5a14.67 14.67 0 0 1 1.65-.79l.93-.37.74 1.86-.93.37A9.88 9.88 0 0 0 13 9.35zM8 19H6V18a11.77 11.77 0 0 1 2.5-7.32l.62-.79 1.57 1.23-.61.78A9.85 9.85 0 0 0 8 18z"/><path fill="#192340" d="M45.71,39.29l-7.5-7.5a4.56,4.56,0,0,0-5.63-.62L30,28.57A16.06,16.06,0,1,0,28.57,30l2.6,2.6a4.56,4.56,0,0,0,.62,5.63l7.5,7.5a1,1,0,0,0,1.42,0l5-5A1,1,0,0,0,45.71,39.29ZM4,18A14,14,0,1,1,18,32,14,14,0,0,1,4,18ZM40,43.59l-6.79-6.8a2.53,2.53,0,1,1,3.58-3.58L43.59,40Z"/></svg></i>
      </a>
    </div>
  </div>
  <div class="product-detail mt-0">
    <div>

      <p class="d-block mt-2 mb-4 font-weight-bold product-name-custom1">
        <a (click)="redirect()">
        {{product?.nm | titlecase}}</a></p>
      <a (click)="redirect()">
        <h6>{{ product?.category_name }}</h6>
        <div class="d-flex warranty-li" *ngIf="product?.no">
          <h6 >Warranty: </h6>
          <h6 [innerHTML]="product?.no"></h6>
        </div>
      </a>
      <p class="greytexted">{{ product?.dsc }}</p>

    </div>
  </div>
  <div>
    <h6  *ngIf="product?.orderType==1" style="text-decoration: line-through;">
      ₹{{product?.bp}} INR
    </h6>
    <h4 *ngIf="product?.orderType==1" class="font-weight-bold text-theme mb-0 text-price-color">
      ₹{{product?.sp}} INR
    </h4>
    <h6 *ngIf="product?.orderType==3" style="text-decoration: line-through;">
      ₹{{product?.bp}} INR
    </h6>
    <h4 *ngIf="product?.orderType==3" class="font-weight-bold text-theme mb-0 text-price-color">
      ₹{{product?.sp}} INR
    </h4>
    <!--<h4 *ngIf="product?.orderType==3" class="font-weight-bold text-theme mb-5">
      ₹{{product?.amrp}} INR
    </h4> -->
    <button (click)="redirect()" class="btn btn-solid btn-theme btn-block m-0 mb-2 list-tag-button" *ngIf="product?.orderType==1">
      Direct Order
    </button>
    <button (click)="redirect()" class="btn btn-solid btn-theme btn-block m-0 mb-2" *ngIf="product?.orderType==3">
      Advance Payment
    </button>
    <button (click)="redirect()" class="btn btn-solid btn-theme btn-block m-0 mb-2 list-order-button" *ngIf="product?.orderType==2">
      Order Via Enquiry
    </button>
  </div>
</div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>

