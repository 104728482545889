<div class="icon-nav">
  <ul>
    <li class="onhover-div mobile-search search-widgets" id="search-widgets">
      <div (click)="searchToggle()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><path fill="#f22e62" d="M12.11 9.84l-1-1.73.86-.5a14.67 14.67 0 0 1 1.65-.79l.93-.37.74 1.86-.93.37A9.88 9.88 0 0 0 13 9.35zM8 19H6V18a11.77 11.77 0 0 1 2.5-7.32l.62-.79 1.57 1.23-.61.78A9.85 9.85 0 0 0 8 18z"/><path fill="#192340" d="M45.71,39.29l-7.5-7.5a4.56,4.56,0,0,0-5.63-.62L30,28.57A16.06,16.06,0,1,0,28.57,30l2.6,2.6a4.56,4.56,0,0,0,.62,5.63l7.5,7.5a1,1,0,0,0,1.42,0l5-5A1,1,0,0,0,45.71,39.29ZM4,18A14,14,0,1,1,18,32,14,14,0,0,1,4,18ZM40,43.59l-6.79-6.8a2.53,2.53,0,1,1,3.58-3.58L43.59,40Z"/></svg>
        <i class="ti-search"></i>
      </div>
      <div id="search-overlay" class="search-overlay" [ngStyle]="{'display': search ? 'block' : 'none' }">
        <div> 
            <span class="closebtn" title="Close Overlay" (click)="searchToggle()">×</span>
            <div class="overlay-content">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <form (submit)="Search()">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="searchOverlay" placeholder="Search a Product">
                                </div>
                                <button type="submit" class="btn btn-primary"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><path fill="#f22e62" d="M12.11 9.84l-1-1.73.86-.5a14.67 14.67 0 0 1 1.65-.79l.93-.37.74 1.86-.93.37A9.88 9.88 0 0 0 13 9.35zM8 19H6V18a11.77 11.77 0 0 1 2.5-7.32l.62-.79 1.57 1.23-.61.78A9.85 9.85 0 0 0 8 18z"/><path fill="#192340" d="M45.71,39.29l-7.5-7.5a4.56,4.56,0,0,0-5.63-.62L30,28.57A16.06,16.06,0,1,0,28.57,30l2.6,2.6a4.56,4.56,0,0,0,.62,5.63l7.5,7.5a1,1,0,0,0,1.42,0l5-5A1,1,0,0,0,45.71,39.29ZM4,18A14,14,0,1,1,18,32,14,14,0,0,1,4,18ZM40,43.59l-6.79-6.8a2.53,2.53,0,1,1,3.58-3.58L43.59,40Z"/></svg></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </li>

    <li class="onhover-div mobile-cart" *ngIf="userData">
      <div>
        <a [routerLink]="['/buyer/cart']" class="mobile-svg-cart">
        <!--  <img src="assets/images/icon/cart.png" class="img-fluid" alt="">-->
        <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" enable-background="new 0 0 158 158" viewBox="0 0 158 158"><path fill="#2C3E50" d="M26,22c0.1,0,0.5,0.3,0.6,0.6L28,26H15c-0.8,0-2-1-2-2c0-0.8,1-2,2-2H26 M114,39  c-1.3,4.3-9.7,24.5-12.5,31.3c-1.2,2.8-1.9,4.6-2.2,5.2C99,76.4,98.3,77,98,77H52c0,0-0.1,0-0.2,0l0,0l-0.2,0  c-0.2-0.1-0.5-0.3-0.7-0.4L36.4,39H114 M26,18H15c-3.2,0-6,3-6,6c0,3.2,3,6,6,6h13.6l18.7,48.1c0.5,1.3,2.2,2.5,3.4,2.7  c0,0,0.6,0.2,1.3,0.2h46c2.2,0,4.2-1.9,5-4c1.1-2.9,15-35.8,15-38s-1.8-4-4-4H36.1l-5.9-14.1C29.6,19.6,28,18,26,18L26,18z"/><path fill="#3B97D3" d="M49 89c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8S44.6 89 49 89M49 85c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12S55.6 85 49 85L49 85zM102 89c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8S97.6 89 102 89M102 85c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12S108.6 85 102 85L102 85z"/><circle cx="49" cy="97" r="3" fill="#3B97D3"/><circle cx="102" cy="97" r="3" fill="#3B97D3"/></svg>
          <i class="ti-shopping-cart"></i>
        </a>
      </div>
      <span class="cart_qty_cls">{{ products.length }}</span>
      <ul class="show-div shopping-cart" *ngIf='!products.length'>
        <h5>Your cart is currently empty.</h5>
      </ul>
      <ul class="show-div shopping-cart" *ngIf='products.length'>
        <li *ngFor="let product of dynamicContent" class="p-4">
          <div class="media">
            <a [routerLink]="['/buyer/cart']">
              <img class="mr-3" [src]="ParseImage(product.zf)" [alt]="product?.nm" style="width: 100px; height:100px; object-fit: cover;">
            </a>
            <div class="media-body">
              <a [routerLink]="['/buyer/cart']">
                <h4>{{ product?.nm }}</h4>
              </a>
              <h4>
                <span>
                  {{ product?.quantity }} x 
                  ₹{{ product?.sp }}
                </span>
              </h4>
            </div>
          </div>
          <div class="removecart">
            <button type=button class="btn btn-danger" (click)="removeItem(product.cart_id)">Remove</button>
            <!-- <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a> -->
          </div>
        </li>
        <li>
          <div class="total">
            <h5>total : <span>₹{{ totalAmount }}</span></h5>
          </div>
        </li>
        <li>
          <div class="buttons">
            <a [routerLink]="['/buyer/cart']" class="view-cart">view cart</a>
            <a [routerLink]="['/buyer/checkout']" class="checkout">checkout</a>
          </div>
        </li>
      </ul>
    </li>

    <li class="onhover-div mobile-cart" *ngIf="userData">
      <div>
        <a href="javascript:void(0)">
          <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 3" viewBox="0 0 288.19 360.2375" x="0px" y="0px"><path d="M144,37.43A106.59,106.59,0,0,0,58.58,207.81a6.75,6.75,0,0,0,.44.65,105.73,105.73,0,0,0,74,41.68l.27,0,2.26.2.9.06c.6,0,1.21.09,1.82.12l1.46.06,1.39.06c.87,0,1.73,0,2.6,0h.62c.87,0,1.74,0,2.6,0l1.4-.06,1.45-.06c.61,0,1.22-.07,1.82-.12l.9-.06,2.26-.2.27,0a105.78,105.78,0,0,0,74-41.68,6.64,6.64,0,0,0,.43-.65A106.59,106.59,0,0,0,144,37.43Zm4.73,198.16h-.42l-1.92.08h-1.34l-1,0-1.05,0h-1.34l-1.92-.08h-.42a91.44,91.44,0,0,1-63.88-30.85,107.92,107.92,0,0,1,137.23,0A91.49,91.49,0,0,1,148.75,235.59Zm72.89-42.86a122.91,122.91,0,0,0-155.24,0,91.64,91.64,0,1,1,155.24,0Z"/><path d="M144,67.48A42.55,42.55,0,1,0,186.56,110,42.58,42.58,0,0,0,144,67.48Zm0,70.09A27.55,27.55,0,1,1,171.56,110,27.57,27.57,0,0,1,144,137.57Z"/><text x="0" y="303.19" fill="#000000" font-size="5px" font-weight="bold" font-family="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"></text><text x="0" y="308.19" fill="#000000" font-size="5px" font-weight="bold" font-family="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"></text></svg>
         <!-- <img src="assets/images/icon/user.png" style="width: 23px; height: 23px;" class="img-fluid" alt="">-->
         <span class="usname-overlap">Hi {{userData.username}}</span>
        </a>
      </div>

      <ul class="show-div shopping-cart">
       
        <li>
          <div class="buttons">
            <a [routerLink]="['/buyer/orders-history']" class="view-cart no-btn">Orders History</a>
          </div>
        </li>
        <li>
          <div class="buttons">
            <a [routerLink]="['/buyer/edit-profile']" class="view-cart  no-btn">Edit Profile</a>
          </div>
        </li>
        <li>
          <div class="buttons">
            <a href="javascript:void(0)" (click)="logout()" class="view-cart  no-btn">Logout</a>
          </div>
        </li>
      </ul>
    </li>

    <li class="onhover-div mobile-cart" *ngIf="!userData">
      <div>
        <a [routerLink]="['/buyer/login']">
          <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 3" viewBox="0 0 288.19 360.2375" x="0px" y="0px"><path d="M144,37.43A106.59,106.59,0,0,0,58.58,207.81a6.75,6.75,0,0,0,.44.65,105.73,105.73,0,0,0,74,41.68l.27,0,2.26.2.9.06c.6,0,1.21.09,1.82.12l1.46.06,1.39.06c.87,0,1.73,0,2.6,0h.62c.87,0,1.74,0,2.6,0l1.4-.06,1.45-.06c.61,0,1.22-.07,1.82-.12l.9-.06,2.26-.2.27,0a105.78,105.78,0,0,0,74-41.68,6.64,6.64,0,0,0,.43-.65A106.59,106.59,0,0,0,144,37.43Zm4.73,198.16h-.42l-1.92.08h-1.34l-1,0-1.05,0h-1.34l-1.92-.08h-.42a91.44,91.44,0,0,1-63.88-30.85,107.92,107.92,0,0,1,137.23,0A91.49,91.49,0,0,1,148.75,235.59Zm72.89-42.86a122.91,122.91,0,0,0-155.24,0,91.64,91.64,0,1,1,155.24,0Z"/><path d="M144,67.48A42.55,42.55,0,1,0,186.56,110,42.58,42.58,0,0,0,144,67.48Zm0,70.09A27.55,27.55,0,1,1,171.56,110,27.57,27.57,0,0,1,144,137.57Z"/><text x="0" y="303.19" fill="#000000" font-size="5px" font-weight="bold" font-family="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"></text><text x="0" y="308.19" fill="#000000" font-size="5px" font-weight="bold" font-family="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"></text></svg>
          <i class="ti-search"></i>
        </a>
      </div>
    </li>
  </ul>
</div>